<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>This consult has been completed</h1>
      <p>Looks like either you or another provider has already completed this consult. You can safely ignore the notification that took you here and wait for the next one.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-completed'
}
</script>
