<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>We'll try you again later</h1>
      <p>You declined this consult which means you're probably busy. We'll stop sending you consults for the next 10 minutes. Thanks for letting us know.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-declined'
}
</script>
