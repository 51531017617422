<template>
  <div class="date-controls-container">

    <div class="date-range-container clinician-month-picker">
      <span class="date-range-seperator">Select Month</span>
      <b-field>
        <b-datepicker
          type="month"
          pack="fas"
          placeholder="Loading..."
          icon-pack="fas"
          icon="calendar"
          @input="this.updatedPicker"
          v-model="monthSelected">
        </b-datepicker>
      </b-field>
    </div>

    <b-button
        class="chart-refresh is-light"
        @click="this.refreshCharts"
        icon-left="sync"
        :disabled="this.refreshDisabled"
        icon-pack="fa">
    </b-button>

  </div>
</template>

<script>
const TODAY = new Date()

export default {
  data () {
    return {
      monthSelected: null,
      refreshDisabled: false
    }
  },
  methods: {
    refreshCharts: function () {
      this.$emit('refreshCharts', 'refresh')
    },
    setInitialState () {
      this.monthSelected = TODAY
    },
    emitBounds: function () {
      const offset = TODAY.getTimezoneOffset()

      let lowerBoundFriendly = new Date(this.monthSelected.getTime() + (offset * 60 * 1000))
      lowerBoundFriendly = this.$moment(lowerBoundFriendly).format('YYYY-MM')

      let upperBoundFriendly = new Date(this.monthSelected.getTime() + (offset * 60 * 1000))
      upperBoundFriendly = this.$moment(upperBoundFriendly).add(1, 'months')
      upperBoundFriendly = this.$moment(upperBoundFriendly).format('YYYY-MM')

      this.$emit('dateBounds', lowerBoundFriendly, upperBoundFriendly)
    },
    updatedPicker () {
      this.emitBounds()
      this.$nextTick(() => { this.refreshCharts() })
    }
  },
  created () {
    this.setInitialState()
    this.refreshDisabled = false
    this.emitBounds()
  }
}
</script>
