import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueMultianalytics from 'vue-multianalytics'

Vue.config.productionTip = false

let mixpanelConfig = {
  token: '2fe45de59b9f203807ddc9f9d2e8a18c'
}

Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: { App }
})
