<template>
<div class="login-toggle-container">
  <h2>Login as ...</h2>
  <b-field>
    <b-radio-button
      v-model="loginUser"
      native-value="clinician"
      @input="this.emitLoginUser"
      >
        Clinician
    </b-radio-button>

    <b-radio-button
      v-model="loginUser"
      native-value="employer"
      @input="this.emitLoginUser"
      >
        Employer
    </b-radio-button>
  </b-field>
</div>
</template>

<script>
export default {
  data () {
    return {
      loginUser: 'clinician'
    }
  },
  methods: {
    emitLoginUser () {
      let loginUserType = this.loginUser
      this.$emit('loginUserType', loginUserType)
    }
  },
  created () {
    this.emitLoginUser()
  }
}
</script>
