<template>
  <iframe
    :src="iframe_url"
    class="metabase-chart"
    frameborder="0"
    width="100%"
    allowtransparency
  ></iframe>
</template>

<script>
const axios = require('axios')
const METABASE_SITE_URL = 'https://data.enzymehealth.com'

export default {
  name: 'metabase-chart',
  props: {
    question: {
      required: true,
      type: Number
    },
    questionSandbox: {
      required: true,
      type: Number
    },
    params: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: function () {
    return {
      iframe_url: null
    }
  },
  computed: {
    environment () {
      return this.$store.state.environment
    }
  },
  created () {
    this.loadChart()
  },
  methods: {
    loadChart () {
      let q = (this.environment === 'production') ? this.question : this.questionSandbox

      let payload = {
        resource: { question: q },
        params: this.params
      }

      axios({
        method: 'post',
        url: process.env.LAMBDA_URL + '/metabase',
        data: JSON.stringify(payload)
      })
      .then((response) => {
        let token = response.data

        this.iframe_url = METABASE_SITE_URL + '/embed/question/' + token + '#bordered=false&titled=false'
      })
      .catch((error) => {
        console.error(error)
      })
    }
  }
}
</script>
