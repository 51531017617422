/*

Sets a $user variable on Vue.prototype allowing it to be accessed throughout app
Use convenience methods
  $user.getName()
or directly
  $user.user_type

*/
import store from '../store'
const axios = require('axios')

const User = {
  data: null,
  install (Vue, options) {
    const vm = new Vue({
      data: {
        authenticated: false,
        sub: null,
        name: null,
        email: null,
        given_name: null,
        family_name: null,
        user_type: null,
        sandboxAPIKey: null,
        productionAPIKey: null,
        betaAccess: false
      },
      methods: {
        async clearSandbox () {
          const token = await Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'post',
            url: process.env.ON_DEMAND_SANDBOX_API + '/internal/clients/clear',
            crossdomain: true,
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        },
        async getClinicianData () {
          const storedData = store.getters.clinicianData
          if (
            storedData &&
            new Date().getTime() < new Date(storedData.validUntil).getTime()
          ) {
            return storedData
          } else {
            const token = await Vue.prototype.$auth.getAccessToken()
            const res = await axios({
              method: 'get',
              url: process.env.ON_DEMAND_API + '/prototype/clinician_data',
              crossdomain: true,
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
              }
            })
            const validUntil = new Date(new Date().getTime() + 20000).getTime() // keep this data for 20 seconds
            const newData = { ...res.data, validUntil }
            store.commit('setClinicianData', newData)
            return newData
          }
        },
        async setAvailability (available, id) {
          const token = await Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'patch',
            url: process.env.ON_DEMAND_API + '/internal/clinicians/' + id,
            data: JSON.stringify({
              clinician: {
                available: available
              }
            }),
            crossdomain: true,
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
          })
        },
        async getUser () {
          let properties = await Vue.prototype.$auth.getUser()

          if (properties) {
            this.sub = properties.sub
            this.name = properties.name
            this.email = properties.email
            this.given_name = properties.given_name
            this.family_name = properties.family_name
            this.sandboxAPIKey = properties.sandboxAPIKey
              ? properties.sandboxAPIKey
              : ''
            this.productionAPIKey = properties.productionAPIKey
              ? properties.productionAPIKey
              : ''
            this.betaAccess = properties.betaAccess

            let isEmployer =
              this.sandboxAPIKey.includes('sandbox_') ||
              this.productionAPIKey.includes('live_')
            this.user_type = isEmployer ? 'employer' : 'clinician'
          }

          return properties
        },
        async isAuthenticated () {
          this.authenticated = await Vue.prototype.$auth.isAuthenticated()
          return this.authenticated
        },
        async isEmployer () {
          await this.getUser()
          return this.user_type === 'employer'
        },
        async isProductionReady () {
          await this.getUser()
          return (
            this.productionAPIKey && this.productionAPIKey.includes('live_')
          )
        },
        async isBetaUser () {
          await this.getUser()
          return this.betaAccess === true
        },
        async isClinician () {
          await this.getUser()
          return this.user_type === 'clinician'
        },
        async getRole () {
          await this.getUser()
          return this.user_type
        },
        async getName () {
          await this.getUser()
          return this.given_name + ' ' + this.family_name
        },
        async getEmail () {
          await this.getUser()
          return this.email
        },
        async logout () {
          this.update()
        },
        async update () {
          await this.isAuthenticated()
          await this.getUser()
        },
        loading () {
          return this.email === null || this.email === undefined
        }
      }
    })

    Vue.prototype.$user = vm
  }
}

export default User
