<template lang="html">
  <div class="card grouped-training">
    <header class="card-header">
      <h3 class="card-header-title title is-5">Test</h3>
        <span class="card-header-icon">
          <span>Complete</span>
        </span>
    </header>
    <div class="card-content">
      <ul>
        <!-- <li v-for="i in tdata">
          <a :href="i.Link" v-text="i.Name" target="_blank"></a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'training-card',
  props: {
    tdata: {
      type: Object
    }
  },
  data: function () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="css">
</style>
