<template lang="html">
  <div id="earnings">
    <template v-if="clinicianEmail">
      <section class="hero is-small earnings-heading">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column">
                <h1 class="title is-5">Earnings</h1>
                <p>Adjust the date range to see how much you've earned and which consults you have completed in a given month.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section earnings-body">
        <div class="container">
          <div class="columns date-header">
            <div class="column is-one-half current-date-range">
              <div>
                <span>
                  Now Viewing |
                  <strong>{{ this.lowerBoundDate }}</strong> to <strong>{{ this.upperBoundDate }}</strong>
                </span>
              </div>
            </div>
            <div class="column is-one-half">
              <clinician-date-buttons @dateBounds="setDateParams" @refreshCharts="refreshCharts"></clinician-date-buttons>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-third">
              <div class="dashboard-card" v-if="this.upperBoundDate"><metabase-chart :key="componentKey" :question="264" :questionSandbox="264" :params="paramsWithDates"></metabase-chart></div>
            </div>
            <div class="column is-one-half">
              <div class="dashboard-card" v-if="this.upperBoundDate"><metabase-chart :key="componentKey" :question="265" :questionSandbox="265" :params="paramsWithDates"></metabase-chart></div>
            </div>
            <div class="column is-one-third">
              <div class="dashboard-card" v-if="this.upperBoundDate"><metabase-chart :key="componentKey" :question="287" :questionSandbox="287" :params="paramsWithDates"></metabase-chart></div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="dashboard-card dashboard-card__more-padding dashboard-card__large-card" v-if="this.upperBoundDate"><metabase-chart :key="componentKey" :question="262"  :questionSandbox="262" :params="paramsWithDates"></metabase-chart></div>
            </div>
          </div>
        </div>

      </section>

    </template>
</div>
</template>

<script>
import MetabaseChart from '@/components/MetabaseChart'
import ClinicianDateButtons from '@/components/widgets/ClinicianDateButtons'

export default {
  name: 'Earnings',
  components: {
    'metabase-chart': MetabaseChart,
    'clinician-date-buttons': ClinicianDateButtons
  },
  data: function () {
    return {
      lowerBoundDate: null,
      upperBoundDate: null,
      componentKey: 0,
      currentMonth: null,
      clinicianEmail: null
    }
  },
  methods: {
    setDateParams (lowerBound, upperBound) {
      this.lowerBoundDate = lowerBound
      this.upperBoundDate = upperBound
    },
    refreshCharts (event) {
      this.componentKey += 1
    }
  },
  created () {
    this.clinicianEmail = this.$user.email
  },
  computed: {
    paramsWithDates () {
      return {
        clinician_email: this.clinicianEmail,
        lower_bound_date: this.lowerBoundDate,
        upper_bound_date: this.upperBoundDate
      }
    },
    environment () {
      return this.$store.state.environment
    }
  }
}
</script>
