<template>
  <div class="container-fluid">

    <nav class="navbar is-white main-nav">
      <div class="container">

        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <img src="@/assets/images/enzyme-logo-purple.svg" width="112" height="28">
          </a>

          <a role="button" class="navbar-burger burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">
          <div class="navbar-start">
            <router-link
              class="navbar-item"
              to="/"
            >
            Home
            </router-link>

            <!-- Clinician Links -->
            <template v-if="$user.authenticated && isClinician">
              <router-link
                class="navbar-item"
                to="/trainings"
              >
                Trainings
              </router-link>
              <router-link
                class="navbar-item"
                to="/rates"
              >
                Consult Rates
              </router-link>
              <router-link
                class="navbar-item"
                to="/earnings"
              >
                Earnings
              </router-link>
            </template>
            <!-- End Clinician Links -->

            <!-- Employer Links -->
            <template v-if="$user.authenticated && isEmployer">
              <router-link
                class="navbar-item"
                to="/clinician-roster"
              >
                Clinician Roster
              </router-link>
            </template>
            <!-- End Employer Links -->

          </div>

          <div class="navbar-end">

            <!-- Employer Links -->
            <template v-if="$user.authenticated && isEmployer">
              <div class="navbar-item" v-if="this.environment == 'sandbox'">
                <span class="tag is-warning is-medium sandbox-warning">
                  <span>
                  <b-icon
                      pack="fas"
                      icon="exclamation-triangle"
                      size="is-small">
                  </b-icon>
                </span>
                  Sandbox Mode
                </span>
              </div>
            </template>
            <!-- End Employer Links -->

            <div class="navbar-item">
              <div class="buttons">
                <router-link
                  to="/login"
                  class="button is-purple"
                  v-if="$user.authenticated == false"
                >
                Login
                </router-link>
              </div>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">

              <a v-if="$user.authenticated" class="navbar-link">
                Menu
              </a>

              <div class="navbar-dropdown is-right">
                <template v-if="$user.authenticated && isEmployer">
                  <div class="dropdown-item">
                    <span>Select Environment</span>
                  </div>
                  <div class="dropdown-item">
                    <div class="field env-switch">
                        <b-switch
                          @input="sendEnvironment"
                          v-model="environment"
                          :disabled="!productionReady"
                          true-value="sandbox"
                          false-value="production">
                          Enable Sandbox Mode
                        </b-switch>
                    </div>
                  </div>
                  <div v-if="environment == 'sandbox'" class="has-text-centered">
                    <hr class="dropdown-divider">
                    <div class="dropdown-item" >
                      <button class="button is-danger" @click="clearSandboxConfirmation">
                        <span class="icon">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                        <span>Reset Sandbox</span>
                      </button>
                    </div>
                  </div>
                  <hr class="dropdown-divider">
                </template>
                <a
                  class="navbar-item"
                  id="logout-button"
                  v-if="$user.authenticated"
                  v-on:click="sendLogout"
                >
                Logout
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </nav>

  </div>

</template>

<script>
import store from '@/store'

export default {
  name: 'top-navigation',
  data: function () {
    return {
      showNav: false,
      productionReady: false,
      isClinician: false,
      isEmployer: false,
      environment: ''
    }
  },
  methods: {
    sendLogout () {
      this.$emit('logout-request')
      this.$router.push({ path: '/login' })
    },
    sendEnvironment (environment) {
      store.commit('setEnvironment', environment)
    },
    clearSandbox () {
      this.$user.clearSandbox()
      .then(() => {
        this.$toast.open('Sandbox cleared')
      })
      .catch(() => {
        this.$toast.open('Error! Please contect support')
      })
    },
    clearSandboxConfirmation () {
      this.$dialog.confirm({
        type: 'is-danger',
        title: 'Are you sure?',
        message: 'This wipe all consult data (including those in progress) from your sandbox environment.',
        confirmText: 'Clear Sandbox Data',
        onConfirm: this.clearSandbox
      })
    },
    async setInitialEnvironment () {
      this.isEmployer = await this.$user.isEmployer()
      this.isClinician = !this.isEmployer

      let isProductionReady = await this.$user.isProductionReady()

      if (this.isClinician) {
        this.environment = 'production'
        this.$store.commit('setEnvironment', 'production')
      } else if (this.isEmployer && isProductionReady) {
        this.environment = 'production'
        this.productionReady = true
        this.$store.commit('setEnvironment', 'production')
      } else if (this.isEmployer && !isProductionReady) {
        this.environment = 'sandbox'
        this.$store.commit('setEnvironment', 'sandbox')
      }
    }
  },
  created () {
    this.setInitialEnvironment()
  }
}
</script>
