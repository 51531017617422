<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>You just missed it</h1>
      <p>We had to reassign this consult to another clinician to prevent longer than expected wait times for patients. Be on the look out more consults coming in soon.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-expired'
}
</script>
