<template>

  <div id="app">
    <top-navigation :key="this.$user.authenticated" @logout-request="logout"></top-navigation>
    <div id="router-view-container">
      <router-view/>
    </div>
    <global-footer></global-footer>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation'
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'app',
  data: function () {
    return {
    }
  },
  components: {
    'top-navigation': TopNavigation,
    'global-footer': GlobalFooter
  },
  created () {
    this.$user.update()
  },
  watch: {
    // Everytime the route changes, check for auth status
    '$route': 'update'
  },
  methods: {
    async update () {
      this.$user.update()
    },
    async logout () {
      await this.$auth.logout()
      await this.$user.logout()
    }
  }
}
</script>
