<template>
  <div id="home">
    <section class="section home-body">
      <div class="container employer-home">
        <b-loading :active.sync="$user.loading()"></b-loading>
        <template v-if="client_id">
          <div class="columns">
            <div class="column">
              <h1 class="title is-2">Overall Performance</h1>
            </div>
            <div class="column">
              <date-buttons @dateBounds="setDateParams" @refreshCharts="refreshCharts"></date-buttons>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-fifth">
              <div class="dashboard-card"><metabase-chart :key="componentKey" :question="157" :questionSandbox="173" :params="params"></metabase-chart></div>
            </div>
            <div class="column is-one-fifth">
              <div class="dashboard-card"><metabase-chart :key="componentKey" :question="404" :questionSandbox="405" :params="params"></metabase-chart></div>
            </div>
            <div class="column is-one-fifth">
              <div class="dashboard-card"><metabase-chart :key="componentKey" :question="164" :questionSandbox="174" :params="paramsWithDates"></metabase-chart></div>
            </div>
              <div class="column is-one-fifth">
                <div class="dashboard-card"><metabase-chart :key="componentKey" :question="165" :questionSandbox="175" :params="paramsWithDates"></metabase-chart></div>
            </div>
              <div class="column is-one-fifth">
                <div class="dashboard-card"><metabase-chart :key="componentKey" :question="166" :questionSandbox="176" :params="paramsWithDates"></metabase-chart></div>
            </div>
          </div>
          <h2 class="title is-4">Consults by Type</h2>
          <div class="columns">
            <div class="column">
              <div class="dashboard-card dashboard-card__more-padding dashboard-card__large-card"><metabase-chart :question="170" :questionSandbox="177" :key="componentKey" :params="paramsWithDates"></metabase-chart></div>
            </div>
          </div>
          <h2 class="title is-4">Consult History</h2>
          <div class="columns">
            <div class="column">
              <div class="dashboard-card dashboard-card__more-padding dashboard-card__large-card"><metabase-chart :question="168" :questionSandbox="178" :key="componentKey" :params="paramsWithDates"></metabase-chart></div>
            </div>
          </div>
          <h2 class="title is-4">Consults in Progress</h2>
          <div class="columns">
            <div class="column">
              <div class="dashboard-card dashboard-card__more-padding dashboard-card__large-card"><metabase-chart :question="160" :questionSandbox="180" :key="componentKey" :params="params"></metabase-chart></div>
            </div>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import MetabaseChart from '@/components/MetabaseChart'
import DateButtons from '@/components/widgets/DateButtons'

export default {
  name: 'employer-home',
  components: {
    'metabase-chart': MetabaseChart,
    'date-buttons': DateButtons
  },
  data: function () {
    return {
      // Hack around metabase bug, client id not used
      client_id: 99999,
      sandboxAPIKey: this.$user.sandboxAPIKey,
      productionAPIKey: this.$user.productionAPIKey,
      lowerBoundDate: null,
      upperBoundDate: null,
      componentKey: 0
    }
  },
  created () {
  },
  methods: {
    setDateParams (lowerBound, upperBound) {
      this.lowerBoundDate = lowerBound
      this.upperBoundDate = upperBound
    },
    refreshCharts (event) {
      this.componentKey += 1
    }
  },
  computed: {
    params () {
      var currentAPIKey

      if (this.environment === 'production') {
        currentAPIKey = this.productionAPIKey
      } else {
        currentAPIKey = this.sandboxAPIKey
      }

      return {
        // Hack to fix metabase issue
        client_id: this.client_id,
        api_key: currentAPIKey
      }
    },
    paramsWithDates () {
      var currentAPIKey

      if (this.environment === 'production') {
        currentAPIKey = this.productionAPIKey
      } else {
        currentAPIKey = this.sandboxAPIKey
      }

      return {
        api_key: currentAPIKey,
        client_id: this.client_id,
        lower_bound_date: this.lowerBoundDate,
        upper_bound_date: this.upperBoundDate
      }
    },
    environment () {
      return this.$store.state.environment
    }
  },
  watch: {
    environment (newValue, oldValue) {
      this.refreshCharts()
    }
  }
}
</script>
