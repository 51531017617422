<template>
  <div class="login">
    <login-toggle @loginUserType="setLoginUser"></login-toggle>
    <clinician-login v-if="this.loginUserType == 'clinician'"></clinician-login>
    <employer-login v-else></employer-login>
  </div>
</template>

<script>
import LoginToggle from '@/components/widgets/LoginToggle'
import ClinicianLogin from '@/components/logins/ClinicianLogin'
import EmployerLogin from '@/components/logins/EmployerLogin'

export default {
  name: 'Login',
  components: {
    'login-toggle': LoginToggle,
    'clinician-login': ClinicianLogin,
    'employer-login': EmployerLogin
  },
  data () {
    return {
      loginUserType: 'clinician'
    }
  },
  methods: {
    setLoginUser (loginUserType) {
      this.loginUserType = loginUserType
    }
  }
}
</script>
