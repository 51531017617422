<template>
  <div id="okta-signin-container-employer"></div>
</template>

<script>
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

import Config from '../../config'

export default {
  name: 'EmployerLogin',
  data () {
    return {

    }
  },
  methods: {
    setLoginUser (loginUserType) {
      this.loginUserType = loginUserType
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.widget = new OktaSignIn({
        /**
         * Note: when using the Sign-In Widget for an OIDC flow, it still
         * needs to be configured with the base URL for your Okta Org. Here
         * we derive it from the given issuer for convenience.
         */
        baseUrl: Config.oidc.issuer.split('/oauth2')[0],
        clientId: Config.oidc.clientId,
        redirectUri: Config.oidc.redirectUri,
        logo: require('@/assets/logo.png'),
        i18n: {
          en: {
            'primaryauth.title': 'Log In to Wheel',
            'primaryauth.username.placeholder': 'Email Address'
          }
        },
        authParams: {
          responseType: ['id_token', 'token'],
          issuer: Config.oidc.issuer,
          display: 'page',
          scopes: Config.oidc.scope.split(' ')
        },
        idps: [
          {type: 'GOOGLE', id: '0oah2b0rnmiVP5gHZ0h7'}
        ],
        idpDisplay: 'PRIMARY'
      })

      this.widget.renderEl(
        { el: '#okta-signin-container-employer' },
        () => {
          /**
           * In this flow, the success handler will not be called because we redirect
           * to the Okta org for the authentication workflow.
           */
        },
        (err) => {
          throw err
        }
      )
    })
  },
  destroyed () {
    // Remove the widget from the DOM on path change
    this.widget.remove()
  }
}
</script>
