import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    environment: 'sandbox',
    clinicianData: null
  },
  mutations: {
    setEnvironment (state, environment) {
      Vue.set(state, 'environment', environment)
    },
    setClinicianData (state, data) {
      Vue.set(state, 'clinicianData', data)
    }
  },
  getters: {
    environment (state) {
      return state.environment
    },
    clinicianData (state) {
      return state.clinicianData
    }
  }
})
