<template lang="html">
  <footer class="footer">
    <div class="container">
      <div class="footer-links">
        <div class="columns">
          <div class="column footer-left">
            <a href="https://www.wheel.com/legal/privacy-policy" rel="noopener" target="_blank">Privacy Policy</a>
            <a href="https://www.wheel.com/legal/wheel-health-inc-terms-of-use" rel="noopener" target="_blank">Terms of Use</a>
          </div>
          <div class="column footer-right">
            <p>© 2020 Wheel Health, Inc.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'global-footer'
}
</script>
