<template>
  <div>
    <employer-home v-if="isEmployer"></employer-home>
    <clinician-home v-else-if="isEmployer == false"></clinician-home>
  </div>
</template>

<script>
import ClinicianHome from './ClinicianHome.vue'
import EmployerHome from './EmployerHome.vue'

export default {
  data () {
    return {
      isEmployer: null
    }
  },
  components: {
    'clinician-home': ClinicianHome,
    'employer-home': EmployerHome
  },
  methods: {
    async setInitialEnvironment () {
      let isEmployer = await this.$user.isEmployer()
      let isProductionReady = await this.$user.isProductionReady()

      if (isEmployer && isProductionReady) {
        this.$store.commit('setEnvironment', 'production')
        this.isEmployer = true
      } else if (isEmployer && !isProductionReady) {
        this.$store.commit('setEnvironment', 'sandbox')
        this.isEmployer = true
      } else {
        this.isEmployer = false
      }
    }
  },
  created () {
    this.setInitialEnvironment()
  }
}
</script>
