<template lang="html">
  <div id="trainings">
    <b-loading :active.sync="isLoading"></b-loading>
    <section class="hero is-small trainings-heading">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-5">
            Trainings
          </h1>
          <p>See all of the available trainings and whether or not you've completed them below.</p>
        </div>
      </div>
    </section>

    <section class="section trainings-body">
      <div class="container-fluid">
        <div class="container">

          <div class="columns is-multiline" v-show="!this.isLoading">

            <div class="column is-4 is-desktop" v-for="(item, key, index) in this.groupedTrainings" v-if="key=='Wheel'">
              <div class="card grouped-training">
                <header class="card-header">
                  <h3 class="card-header-title title is-5">{{ key }}</h3>
                </header>
                <div class="card-content">
                  <div>
                    <ul v-for="(item, key, index) in item.trainings" v-if="key != 'Quiz'">
                      <h5>{{ key }}</h5>
                      <li v-for="i in item">
                        <a :href="i.Link" v-text="i.Name" target="_blank"></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <footer class="card-footer">
                  <div v-if="enzymeTrainingComplete" class="card-footer-item quiz-complete">
                      <i class="fas fa-check"></i>
                    <span>Quiz Complete</span>
                  </div>
                  <div v-else class="card-footer-item">
                    <a :href="item.quiz.Link" target="_blank"><span>Take the Quiz</span><i class="fas fa-angle-down"></i></a>
                  </div>
                </footer>
              </div>
            </div>

            <div class="column is-4 is-desktop" v-for="(item, key, index) in this.groupedTrainings"  v-if="key!='Wheel'">
              <div class="card grouped-training">
                <header class="card-header">
                  <h3 class="card-header-title title is-5">{{ key }}</h3>
                </header>
                <div class="card-content">
                  <div>
                    <ul v-for="(item, key, index) in item.trainings" v-if="key != 'Quiz'">
                      <h5>{{ key }}</h5>
                      <li v-for="i in item">
                        <a :href="i.Link" v-text="i.Name" target="_blank"></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <footer class="card-footer">
                  <div v-if="item.complete" class="card-footer-item quiz-complete">
                      <i class="fas fa-check"></i>
                    <span>Quiz Complete</span>
                  </div>
                  <div v-else class="card-footer-item">
                    <a :href="item.quiz.Link" target="_blank"><span>Take the Quiz</span><i class="fas fa-angle-down"></i></a>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import TrainingCard from '@/components/TrainingCard'

export default {
  name: 'trainings',
  components: {
    'training-card': TrainingCard
  },
  data: function () {
    return {
      isLoading: true,
      enzymeTrainingComplete: false,
      trainings: [],
      groupedTrainings: {}
    }
  },
  async created () {
    this.isLoading = true
    await this.getTrainings()
    await this.getEnzymeTrainingStatus()
    this.isLoading = false
  },
  methods: {
    async getTrainings () {
      try {
        const response = await this.$airtable.getTrainings()
        this.groupedTrainings = response.data
      } catch (error) {
        console.error(Object.keys(error), error.message)
      }
    },
    async getEnzymeTrainingStatus () {
      try {
        const response = await this.$airtable.getClinician()
        const clinician = response.data
        this.enzymeTrainingComplete = clinician['Enzyme Training Complete']
      } catch (error) {
        console.error(Object.keys(error), error.message)
      }
    }
  }
}
</script>
