<template>
  <div class="date-controls-container">

    <div class="date-range-container" v-show="this.dateSelected == 'range'">
      <span class="date-range-seperator">From</span>
      <b-field>
        <b-datepicker
          v-model="lowerBoundDate"
          @input="this.updatedPicker"
          placeholder="Start"
          icon-pack="fas"
          icon="calendar"
          editable>
        </b-datepicker>
      </b-field>
      <span class="date-range-seperator">to</span>
      <b-field>
          <b-datepicker
            v-model="upperBoundDate"
            @input="this.updatedPicker"
            :min-date="this.lowerBoundDate"
            :max-date="maxDate"
            placeholder="End"
            icon-pack="fas"
            icon="calendar"
            editable>
          </b-datepicker>
      </b-field>
    </div>

    <div class="date-buttons-container" v-show="this.dateSelected != 'range'">
      <b-field>
        <b-radio-button
          v-model="dateSelected"
          native-value="range"
          class="text">
            Select Range...
        </b-radio-button>
        <b-radio-button
          v-model="dateSelected"
          @input="this.updateBounds"
          native-value="lastThirty">
            Last 30 Days
        </b-radio-button>

        <b-radio-button
          v-model="dateSelected"
          @input="this.updateBounds"
          native-value="lastSeven">
            Last 7 Days
        </b-radio-button>

        <b-radio-button
          v-model="dateSelected"
          @input="this.updateBounds"
          native-value="today">
            Today
        </b-radio-button>

      </b-field>
    </div>

    <div class="date-buttons-dropdown" v-show="this.dateSelected == 'range'">
      <b-dropdown aria-role="list" v-model="dateSelected" @input="this.updateBounds">
        <button class="button" slot="trigger">
            <b-icon pack="fas" icon="caret-down"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem" value="today">Today</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" value="lastSeven">Last 7 Days</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" value="lastThirty">Last 30 Days</b-dropdown-item>
      </b-dropdown>
    </div>

    <b-button
        class="chart-refresh is-light"
        @click="this.refreshCharts"
        icon-left="sync"
        :disabled="this.refreshDisabled"
        icon-pack="fa">
    </b-button>
  </div>
</template>

<script>
const TODAY = new Date()

export default {
  data () {
    return {
      dateSelected: 'today',
      date: TODAY,
      today: TODAY,
      lowerBoundDate: this.dateDaysAgo(7),
      upperBoundDate: TODAY,
      maxDate: TODAY,
      refreshDisabled: false
    }
  },
  methods: {
    refreshCharts: function () {
      this.$emit('refreshCharts', 'refresh')
    },
    emitBounds: function () {
      const offset = TODAY.getTimezoneOffset()

      let lowerBoundFriendly = new Date(this.lowerBoundDate.getTime() + (offset * 60 * 1000))
      lowerBoundFriendly = this.$moment(lowerBoundFriendly).format('YYYY-MM-DD')

      let upperBoundFriendly = new Date(this.upperBoundDate.getTime() + (offset * 60 * 1000))
      upperBoundFriendly = this.$moment(upperBoundFriendly).add(1, 'd').format('YYYY-MM-DD')

      this.$emit('dateBounds', lowerBoundFriendly, upperBoundFriendly)
    },
    updateBounds: function (event) {
      if (event === 'lastThirty') {
        this.upperBoundDate = TODAY
        this.lowerBoundDate = this.dateDaysAgo(30)
      } else if (event === 'lastSeven') {
        this.upperBoundDate = TODAY
        this.lowerBoundDate = this.dateDaysAgo(7)
      } else if (event === 'today') {
        this.upperBoundDate = TODAY
        this.lowerBoundDate = TODAY
      }
      this.emitBounds()
      this.$nextTick(() => { this.refreshCharts() })
    },
    updatedPicker () {
      this.emitBounds()
      this.$nextTick(() => { this.refreshCharts() })
    },
    dateDaysAgo (days) {
      const today = new Date()

      if (days === undefined) {
        return today
      }

      return new Date(today.setDate(today.getDate() - days))
    }
  },
  created () {
    this.updateBounds('today')
    this.refreshDisabled = false
    this.emitBounds()
  }
}
</script>
