/*
Sets a $airtable variable on Vue.prototype allowing it to be accessed throughout app
*/
const axios = require('axios')

const Airtable = {
  data: null,
  install (Vue, options) {
    const vm = new Vue({
      methods: {
        async getConsultRates () {
          const token = await Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'get',
            url: process.env.LAMBDA_URL + '/consult-rates',
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        },
        async getTrainings () {
          const token = await Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'get',
            url: process.env.LAMBDA_URL + '/trainings',
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        },
        async getClinician () {
          const token = await Vue.prototype.$auth.getAccessToken()
          return axios({
            method: 'get',
            url: process.env.LAMBDA_URL + '/clinician',
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        }
      }
    })

    Vue.prototype.$airtable = vm
  }
}

export default Airtable
