const CLIENT_ID = process.env.CLIENT_ID
const ISSUER = process.env.ISSUER
const REDIRECT_URI = process.env.REDIRECT_URI
const MESSAGES_URL = process.env.MESSAGES_URL

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scope: 'openid profile email'
  },
  resourceServer: {
    messagesUrl: MESSAGES_URL
  }
}
