import Vue from 'vue'
import Router from 'vue-router'
import AsyncComputed from 'vue-async-computed'
import Buefy from 'buefy'
import '@/assets/scss/main.scss'

import Auth from '@okta/okta-vue'
import Config from '@/config'
import User from '@/utils/User'
import Airtable from '@/utils/Airtable'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import OktaCallback from '@/components/auth/OktaCallback'

import HomeComponent from '@/components/Home'
import LoginComponent from '@/components/Login'
import ProfileComponent from '@/components/Profile'
import MessagesComponent from '@/components/Messages'
import AuthorizeComponent from '@/components/Authorize'
import TrainingsComponent from '@/components/Trainings'
import ConsultRatesComponent from '@/components/ConsultRates'
import AssignmentErrorComponent from '@/components/assignments/Error'
import AssignmentDeclinedComponent from '@/components/assignments/Declined'
import AssignmentCanceledComponent from '@/components/assignments/Canceled'
import AssignmentExpiredComponent from '@/components/assignments/Expired'
import AssignmentPrevDeclinedComponent from '@/components/assignments/PrevDeclined'
import AssignmentCompletedComponent from '@/components/assignments/Completed'
import ClinicianRosterComponent from '@/components/ClinicianRoster'
import EarningsComponent from '@/components/Earnings'

// Add FA Icons we'll use
library.add(faCheck)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Buefy)
Vue.use(AsyncComputed)
Vue.use(Router)
Vue.use(Auth, {
  issuer: Config.oidc.issuer,
  client_id: Config.oidc.clientId,
  redirect_uri: window.location.origin + '/implicit/callback',
  scope: Config.oidc.scope
})
Vue.use(User)
Vue.use(Airtable)
Vue.use(require('vue-moment'))

const axios = require('axios')
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    router.push('/login')
  }
  return error
})

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeComponent,
      meta: {
        permissions: {
          required: true
        }
      }
    },
    {
      path: '/authorize',
      component: AuthorizeComponent,
      meta: {
        permissions: {
          required: true
        }
      }
    },
    {
      path: '/login',
      component: LoginComponent
    },
    {
      path: '/implicit/callback',
      component: OktaCallback
    },
    {
      path: '/profile',
      component: ProfileComponent,
      meta: {
        permissions: {
          required: true
        }
      }
    },
    {
      path: '/messages',
      component: MessagesComponent,
      meta: {
        permissions: {
          required: true,
          role: 'clinician',
          redirect: '/'
        }
      }
    },
    {
      path: '/trainings',
      component: TrainingsComponent,
      meta: {
        permissions: {
          required: true,
          role: 'clinician',
          redirect: '/'
        }
      }
    },
    {
      path: '/rates',
      component: ConsultRatesComponent,
      meta: {
        permissions: {
          required: true,
          role: 'clinician',
          redirect: '/'
        }
      }
    },
    {
      path: '/clinician-roster',
      component: ClinicianRosterComponent,
      meta: {
        permissions: {
          required: true,
          role: 'employer',
          redirect: '/'
        }
      }
    },
    {
      path: '/earnings',
      component: EarningsComponent,
      meta: {
        permissions: {
          required: true,
          role: 'clinician',
          redirect: '/'
        }
      }
    },
    {
      path: '/assignments/error',
      component: AssignmentErrorComponent
    },
    {
      path: '/assignments/declined',
      component: AssignmentDeclinedComponent
    },
    {
      path: '/assignments/canceled',
      component: AssignmentCanceledComponent
    },
    {
      path: '/assignments/expired',
      component: AssignmentExpiredComponent
    },
    {
      path: '/assignments/prev-declined',
      component: AssignmentPrevDeclinedComponent
    },
    {
      path: '/assignments/completed',
      component: AssignmentCompletedComponent
    }
  ]
})

const permit = async (permissions) => {
  if (permissions) {
    if (permissions.required) {
      if (!(await Vue.prototype.$user.isAuthenticated())) {
        return false
      }

      try {
        await Vue.prototype.$user.getUser()
      } catch (error) {
        return false
      }
    }

    if (permissions.role && permissions.role !== await Vue.prototype.$user.getRole()) {
      return false
    }
  }

  return true
}

const onAuthRequired = async (to, from, next) => {
  let permission = await permit(to.meta.permissions)

  if (permission) {
    next()
  } else {
    next({ path: to.meta.permissions.redirect || '/login' })
  }
}

router.beforeEach(onAuthRequired)

export default router
