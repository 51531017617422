<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>Oops! An error has occurred</h1>
      <p>Something isn't quite right. If you experience this error more than once, please <a href="support@wheel.com">contact support</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-error'
}
</script>
