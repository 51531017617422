<template>
  <li class="onboarding-step">
    <div class="card">
      <header class="card-header">
        <span class="card-header-icon">
          <div class="onboarding-step-number">{{ step.order }}</div>
        </span>
        <h4 class="card-header-title title is-5">{{ step.heading }}</h4>
      </header>
      <div class="card-content" v-show="!step.complete">
        <p v-show="step.description">{{ step.description }}</p>
        <div v-show="step.additional" v-html="step.additional" class="additional-content"></div>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item">CTA</a>
      </footer>
    </div>
  </li>
</template>

<script>
export default {
  name: 'home-card',
  props: {
    step: {
      type: Object
    }
  },
  data: function () {
    return {
      step: this.step
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
