<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>The consult was cancelled</h1>
      <p>Unfortunately, this consult request was cancelled by our client and no longer needs attention. Keep an eye out for more consults soon.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-canceled'
}
</script>
