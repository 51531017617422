<template>
  <div id="clinician-roster">
    <section class="section home-body">
      <div class="container employer-home">
        <b-loading :active.sync="$user.loading()"></b-loading>
          <div class="columns">
            <div class="column">
              <h1 class="title is-2">Clinician Roster</h1>
              <div class="column">
                <div class="dashboard-card dashboard-card__more-padding dashboard-card__large-card">
                  <metabase-chart :question="169" :questionSandbox="183" :params="params" :key="componentKey"></metabase-chart>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
   </div>
</template>

<script>
  import MetabaseChart from '@/components/MetabaseChart'

  export default {
    name: 'clinician-roster',
    components: {
      'metabase-chart': MetabaseChart
    },
    data: function () {
      return {
        // Hack around metabase bug, client id not used
        client_id: 99999,
        sandboxAPIKey: this.$user.sandboxAPIKey,
        productionAPIKey: this.$user.productionAPIKey,
        componentKey: 0
      }
    },
    methods: {
      refreshCharts (event) {
        this.componentKey += 1
      }
    },
    computed: {
      params () {
        var currentAPIKey

        if (this.environment === 'production') {
          currentAPIKey = this.productionAPIKey
        } else {
          currentAPIKey = this.sandboxAPIKey
        }

        return {
          // Hack to fix metabase issue
          client_id: this.client_id,
          api_key: currentAPIKey
        }
      },
      environment () {
        return this.$store.state.environment
      }
    },
    watch: {
      environment (newValue, oldValue) {
        this.refreshCharts()
      }
    }
  }
</script>
