<template lang="html">
  <div class="card assigned-consult">
    <div class="columns is-gapless is-mobile">
      <div class="column is-one-half-mobile is-two-thirds consult-details">
        <div class="card-content">
          <div class="current-assignment-header">
            <h2 class="title is-5">You have a consult in progress:</h2>
          </div>
          <p>{{consult.client}}</p>
          <p>{{consult.rate}}</p>
          <p><span class="consult-id">#{{consult.hash}}</span></p>
          <p>
            <strong>
              <countdown :time="consult.seconds_until_sla * 1000" ref="countdown" :auto-start="false">
                <template slot-scope="props">
                  <div v-if="props.totalMilliseconds > 0">
                    Expires in
                    <template v-if="props.hours > 0">
                      {{ props.hours }} hours, 
                    </template>
                    {{ props.minutes }} min
                  </div>
                  <div v-else>
                    <span class="has-text-danger">Expired</span> <span class="has-text-grey has-text-weight-normal">- Please complete as soon as possible.</span></span>
                  </div>
                </template>
              </countdown>
            </strong>
          </p>
        </div>
        <footer class="card-footer">
          <a :href="consult.url" target="_blank" class="button is-link card-footer-item">Go to in progress Consult</a>
        </footer>
      </div>
       <div class="column consult-queue-count">
        <div class="card-content">
          <p><span class="title consult-queue-count-number">{{queueCount}}</span></p>
          <p>more consults in the queue</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'started-consult',
  props: {
    consult: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    queueCount: {
      reqired: true,
      type: Number,
      default: () => (0)
    }
  },
  components: {
    'countdown': VueCountdown
  },
  mounted () {
    this.$refs.countdown.start()
  }
}
</script>
