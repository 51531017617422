<template>
  <div>
    <div class="container" style="margin-top: 2rem;">

      <article class="message is-danger" v-if="this.authorizeError.length > 1">
        <div class="message-header">
          <p>Error :(</p>
        </div>
        <div class="message-body">
          {{ authorizeError }} Please contact support.
          <router-link
            to="/"
          >
          Go Back to Dashboard
          </router-link>
        </div>
      </article>

      <article class="message is-success" v-else-if="this.authorizeSuccess.length > 1">
        <div class="message-header">
          <p>Success!</p>
        </div>
        <div class="message-body">
          Success! Your Stripe account has been successfully created and you can now receive payments. Check your wheel.com email for details.
          <router-link
            to="/"
          >
          Go Back to Dashboard
          </router-link>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'authorize',
  data: function () {
    return {
      claims: '',
      stripeCode: '',
      authorizeSuccess: '',
      authorizeError: ''
    }
  },
  created () {
    this.setup()
    this.getStripeCode()
    this.authorizeStripeAccount(this.stripeCode)
  },
  methods: {
    async setup () {
      this.claims = await this.$auth.getUser()
    },
    getStripeCode () {
      this.stripeCode = this.$route.query.code
    },
    redirectSuccess () {
      var vm = this
      setTimeout(function () {
        vm.$router.push({ name: 'home', query: { application: 'success' } })
      }, 2000)
    },
    authorizeStripeAccount (code) {
      if (
        !code
      ) {
      } else {
        axios({
          method: 'post',
          url: process.env.LAMBDA_URL + '/authorize',
          data: JSON.stringify({
            code: code,
            email: this.claims.email
          })
        })
        .then((response) => {
          if (response.data.hasOwnProperty('error')) {
            this.authorizeError = response.data.error_description
          } else {
            this.authorizeSuccess = response.data
            this.redirectSuccess()
          }
        })
        .catch((error) => {
          // this.authorizeResponse = error
          console.log(error)
        })
      }
    }
  }
}
</script>
