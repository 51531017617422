<template lang="html">
  <div class="card assigned-consult">
    <div class="columns is-gapless is-mobile">
      <div
        class="column is-one-half-mobile is-two-thirds consult-details no-assignment"
      >
        <div class="card-content">
          <h2 class="title is-5 has-text-grey">
            You declined or missed your most recent assignment, so we've paused
            you for a moment.
          </h2>
        </div>
      </div>

      <div class="column consult-queue-count">
        <div class="card-content">
          <p>
            <span class="title consult-queue-count-number">{{
              queueCount
            }}</span>
          </p>
          <p>more consults in the queue</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'no-assigned-consult',
  props: {
    queueCount: {
      reqired: true,
      type: Number,
      default: () => 0
    }
  }
}
</script>
