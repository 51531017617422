<template>
  <div v-if="error" class='error__container'>
    <div>
      <h1>Looks like an error occured while trying to log in</h1>
      <h2>For more context check the error message below</h2>
      <b-message class="error__message" :closable="false" :title="errorTitle" type="is-warning">
        {{error.message}}
      </b-message>
      <a v-on:click="logout">Try to Login Again</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OktaCallback',
  data: function () {
    return {
      error: null,
      errorTitle: 'Error'
    }
  },
  methods: {
    async logout () {
      await this.$auth.logout()

      this.$router.push({ path: '/login' })
    }
  },
  async beforeMount () {
    await this.$auth.handleAuthentication()
      .catch(e => {
        this.error = e
        if (e.name) this.errorTitle = e.name
      })

    if (!this.error) {
      this.$router.replace({
        path: this.$auth.getFromUri()
      })
    }
  }
}
</script>
