<template lang="html">
  <div
    class="availability-toggle-container card"
    v-bind:class="{
      'has-background-success': available && !loading,
      'has-background-danger': !available && !loading
    }"
    @click="updateAvailability"
  >
    <div class="card-content" v-bind:class="{ loading: loading }">
      <h2 v-if="loading" class="title has-text-dark">Loading...</h2>
      <h2 v-else class="title has-text-white">
        You are currently
        <strong class="has-text-dark">{{ status }}</strong>
      </h2>
      <p class="is-size-5 has-text-dark" v-if="!available && onShift">
        You're on shift but not toggled online. Increase your earnings by
        turning yourself on for Async consults!
      </p>
      <div v-if="!loading" class="field availability-toggle">
        <b-switch
          :disabled="disableButton"
          v-model="available"
          type="is-dark"
          size="is-large"
        >
        </b-switch>
        <span class="has-text-white"
          >Tap or click here to go
          <strong class="has-text-white">{{ reverseStatus }}</strong
          >.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      available: null,
      onShift: null,
      clinicianId: null,
      email: null,
      disableButton: false
    }
  },
  computed: {
    status () {
      return this.displayAvailability(this.available)
    },
    reverseStatus () {
      return this.displayAvailability(!this.available)
    }
  },
  methods: {
    displayAvailability (available) {
      return available ? 'online' : 'offline'
    },
    updateAvailability () {
      if (this.disableButton) return
      this.disableButton = true
      setTimeout(() => {
        this.disableButton = false
      }, 1000)

      this.$user.setAvailability(!this.available, this.clinicianId).then(() => {
        this.available = !this.available

        var availabilityStatus

        if (this.available) {
          availabilityStatus = 'online'
        } else {
          availabilityStatus = 'offline'
        }

        this.$ma.trackEvent({
          action: 'Toggle Availability',
          properties: {
            'User ID': this.clinicianId,
            Email: this.email,
            'New Availability Status': availabilityStatus
          }
        })
      })
    },
    goOnlineToast () {
      this.$toast.open({
        message:
          "You've gone online and are now available to receive consults.",
        type: 'is-dark',
        duration: 4000,
        queue: false
      })
    },
    goOfflineToast () {
      this.$toast.open({
        message:
          "You've gone offline and won't receive any additional consults until you go back online.",
        type: 'is-dark',
        duration: 4000,
        queue: false
      })
    },
    errorToast () {
      this.$toast.open({
        message: 'Oops something went wrong.  Refresh the page and try again.',
        type: 'is-warning',
        duration: 4000,
        queue: false
      })
    },
    async setInitialAvailability () {
      const { available, id, on_shift: onShift } = await this.$user.getClinicianData()
      this.email = await this.$user.getEmail()

      if (available != null) {
        this.available = available
        this.onShift = onShift
        this.clinicianId = id
        this.loading = false
      } else {
        this.errorToast()
      }
    }
  },
  watch: {
    available (val, oldVal) {
      if (oldVal == null) return
      this.available ? this.goOnlineToast() : this.goOfflineToast()
    }
  },
  created () {
    this.setInitialAvailability()
  }
}
</script>
