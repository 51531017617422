<template>
  <div id="home">
    <div v-if="!this.allStepsComplete">

      <section class="hero is-small">
        <div class="hero-body">
          <div class="container">
            <h1 class="title is-5">
            Welcome!
            </h1>
            <p>
              Please complete the steps below to finish the onboarding process and start taking consults.
            </p>
          </div>
        </div>
      </section>

      <section class="section home-body">
        <div class="container-fluid">
          <div class="container">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns">
              <div class="column">
                <ol v-if="" v-show="!this.isLoading" id="onboarding-list">
                  <h3 class="card-section-header">Onboarding Steps</h3>
                  <li class="onboarding-step" v-show="!isLoading" v-for="(item, key, index) in onboardingSteps">
                    <div class="card">
                      <header class="card-header">
                        <span class="card-header-icon">
                          <div v-if="item.complete" class="completed-step-icon">
                            <i class="fas fa-check"></i>
                          </div>
                          <div v-else class="onboarding-step-number">{{ item.order }}</div>
                        </span>
                        <h4 class="card-header-title title is-5">{{ item.heading }}</h4>
                      </header>

                      <div class="card-content" v-if="!item.complete">
                        <p v-if="!item.complete">{{ item.description }}</p>
                        <p v-else >{{ item.descriptionComplete }}</p>
                      </div>

                      <footer class="card-footer" v-if="!item.complete">
                        <stripe-button v-if="key === 'payments'" :account-id="stripeAccountId" :dashboard-url="stripeDashboardUrl" :connect-url="stripeConnectUrl"></stripe-button>
                        <a v-else :href="item.ctaLink" class="card-footer-item is-purple"><span>{{ item.ctaText }}</span><i class="fas fa-angle-down"></i></a>
                      </footer>
                    </div>

                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-else-if="this.allStepsComplete" :class="{ 'onboarding-complete': this.allStepsComplete }">

      <section class="hero is-small">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-two-thirds">
                <h1 class="title is-5">
                  <span>Hello,</span> {{ this.claims.given_name }}.
                </h1>
                <p>
                  Welcome to your On Demand dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="dashboard-cards">
            <div class="columns">
              <div class="column is-half">
                <availability-toggle></availability-toggle>
              </div>
              <div class="column is-half">
                <assigned-consult></assigned-consult>
              </div>
            </div>
          </div>
         </div>
      </section>

      <section class="section home-body">
        <div class="container-fluid">
          <div class="container">
            <b-loading :active.sync="isLoading"></b-loading>
            <h3 class="card-section-header">Resources</h3>
            <div class="columns dashboard-cards">
              <div class="column" v-show="!isLoading" v-if="item.headingComplete.length > 3" v-for="(item, key, index) in onboardingSteps">

                <div class="card">
                  <div>
                    <header class="card-header">
                      <span class="card-header-icon">
                        <i :class="item.headingIcon"></i>
                      </span>
                      <h4 class="card-header-title title is-5">{{ item.headingComplete }}</h4>
                    </header>

                    <div class="card-content">
                      <p>{{ item.descriptionComplete }}</p>
                    </div>
                  </div>

                  <footer class="card-footer">
                    <stripe-button v-if="key === 'payments'" :account-id="stripeAccountId" :dashboard-url="stripeDashboardUrl" :connect-url="stripeConnectUrl"></stripe-button>
                    <a v-else :href="item.ctaLinkComplete" target="_blank" class="card-footer-item"><span>{{ item.ctaTextComplete }}</span><i class="fas fa-angle-down"></i></a>
                  </footer>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HomeCard from '@/components/HomeCard'
import AvailabilityToggle from '@/components/widgets/AvailabilityToggle'
import AssignedConsult from '@/components/widgets/AssignedConsult'
import StripeButton from '@/components/StripeButton'
const axios = require('axios')

export default {
  name: 'home',
  components: {
    'home-card': HomeCard,
    'availability-toggle': AvailabilityToggle,
    'assigned-consult': AssignedConsult,
    'stripe-button': StripeButton
  },
  data: function () {
    return {
      authenticated: null,
      claims: null,
      isLoading: true,
      personalEmail: '',
      airtableRecordID: '',
      trackingTimeInviteLink: '',
      stripeAccountId: '',
      stripeConnectUrl: '',
      stripeDashboardUrl: '',
      onboardingSteps: {
        payments: {
          active: true,
          order: 1,
          heading: 'Create Stripe Account',
          headingComplete: 'View Stripe Dashboard',
          headingIcon: 'fab fa-stripe-s',
          description: 'Stripe is a service that allows us to quickly pay our clinicians. Create an account, connect your bank, and get timely payments.',
          descriptionComplete: 'View your current account balance and payout history. We automatically log you in with one click!',
          ctaLink: '',
          ctaText: '',
          complete: false
        },
        enzymeTraining: {
          active: true,
          order: 2,
          heading: 'Complete Wheel Training',
          headingComplete: '',
          headingIcon: 'far fa-graduation-cap',
          description: 'Watch a short video and then take the Wheel Training quiz to ensure you know how to use our platform',
          ctaLink: '/trainings',
          ctaText: 'Go to Training',
          ctaLinkComplete: '',
          ctaTextComplete: '',
          complete: false
        }
      },
      betaAccess: false
    }
  },
  async created () {
    this.isLoading = true
    await this.setup()
    await this.getAirtableData()
    this.isLoading = false
  },
  methods: {

    stripeDashboard (id) {
      var vm = this

      if (
        !id
      ) {
      } else {
        axios({
          method: 'post',
          url: process.env.LAMBDA_URL + '/connect',
          data: JSON.stringify({
            accountId: id
          })
        })
        .then((response) => {
          vm.stripeDashboardUrl = response.data.url
          vm.onboardingStepsStatus()
        })
        .catch((error) => {
          // this.authorizeResponse = error
          console.log(error)
        })
      }
    },

    async getAirtableData () {
      try {
        const response = await this.$airtable.getClinician()
        const clinician = response.data

        const stripeOnboardingComplete = Boolean(clinician['Stripe Onboarding Complete'])
        const enzymeTrainingComplete = Boolean(clinician['Enzyme Training Complete'])
        const stripeId = clinician['Stripe Account ID']

        this.onboardingSteps['payments']['complete'] = stripeOnboardingComplete
        this.onboardingSteps['enzymeTraining']['complete'] = enzymeTrainingComplete

        if (stripeId) {
          this.stripeDashboard(stripeId)
        }

        this.personalEmail = clinician['Personal Email Address']

        this.onboardingStepsStatus()
        this.stripeConnect()
      } catch (error) {
        console.error(Object.keys(error), error.message)
        var friendlyError = error.message.charAt(0).toUpperCase() + error.message.slice(1) + '. Please refresh the page to try again.'
        this.$toast.open({
          message: friendlyError,
          type: 'is-danger',
          duration: 4000,
          queue: false
        })
      }
    },
    async setup () {
      this.claims = await this.$auth.getUser()
      this.betaAccess = this.claims.betaAccess
    },
    stripeConnect () {
      var vm = this
      var email = (vm.personalEmail == null) ? vm.claims.email : vm.personalEmail
      let firstName = vm.claims.given_name
      let lastName = vm.claims.family_name
      let url = 'https://connect.stripe.com/express/oauth/authorize?client_id=' + process.env.STRIPE_CLIENT_ID
      let stripeCapabilities = '&suggested_capabilities[]=transfers&suggested_capabilities[]=tax_reporting_us_1099_misc'
      let stripeUrl = url + '&stripe_user[email]=' + email + '&stripe_user[first_name]=' + firstName + '&stripe_user[last_name]=' + lastName + stripeCapabilities
      vm.stripeConnectUrl = stripeUrl
    },
    onboardingStepsStatus () {
      var rawSteps = this.onboardingSteps
      var steps = Object.keys(rawSteps)
      var allStepsComplete = true

      function checkAllComplete () {
        steps.forEach(function (step) {
          if (!rawSteps[step]['complete']) {
            allStepsComplete = false
          }
        })
      }

      checkAllComplete()
      return allStepsComplete
    }
  },
  asyncComputed: {
    async allStepsComplete () {
      var allStepsComplete = await this.onboardingStepsStatus()
      return allStepsComplete
    }
  }
}
</script>
