<template lang="html">
  <div>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="!loading">
      <assigned-consult
        :consult="clinicianData.assigned_consult"
        :queueCount="clinicianData.assignable_consults"
        :clinicianData="clinicianData"
        v-if="isAssigned"
      />
      <started-consult
        :consult="clinicianData.assigned_consult"
        :queueCount="clinicianData.assignable_consults"
        :clinicianData="clinicianData"
        v-else-if="isStarted"
      />
      <no-assigned-consult
        :queueCount="clinicianData.assignable_consults"
        :clinicianData="clinicianData"
        v-else-if="isIdle"
      />
      <paused
        :queueCount="clinicianData.assignable_consults"
        :clinicianData="clinicianData"
        v-else-if="isPaused"
      />
      <!-- No final else so nothing is shown in the error state -->
    </div>
  </div>
</template>

<script>
import AssignedConsult from '@/components/currentConsult/AssignedConsult'
import StartedConsult from '@/components/currentConsult/StartedConsult'
import NoAssignedConsult from '@/components/currentConsult/NoAssignedConsult'
import Paused from '@/components/currentConsult/Paused'

export default {
  data () {
    return {
      clinicianData: null,
      refreshInterval: null
    }
  },
  computed: {
    isAssigned () {
      return (
        this.clinicianData &&
        this.clinicianData.assigned_consult &&
        this.clinicianData.assigned_consult.status === 'assigned'
      )
    },
    isStarted () {
      return (
        this.clinicianData &&
        this.clinicianData.assigned_consult &&
        this.clinicianData.assigned_consult.status === 'started'
      )
    },
    isPaused () {
      return this.clinicianData && this.clinicianData.paused === true
    },
    isIdle () {
      return (
        this.clinicianData &&
        !this.clinicianData.status &&
        Object.keys(this.clinicianData.assigned_consult).length === 0
      )
    },
    loading () {
      return !this.clinicianData
    }
  },
  components: {
    'assigned-consult': AssignedConsult,
    'started-consult': StartedConsult,
    'no-assigned-consult': NoAssignedConsult,
    paused: Paused
  },
  methods: {
    async fetchData () {
      this.clinicianData = await this.$user.getClinicianData()
      this.clinicianData.email = await this.$user.getEmail()
    }
  },
  mounted () {
    this.fetchData()
    this.refreshInterval = setInterval(this.fetchData, 5000)
  },
  beforeDestroy () {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }
}
</script>
