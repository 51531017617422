<template>
  <div id="consult-rates">

    <section class="hero is-small consult-rates-heading">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-5">
            Consult Rates
          </h1>
          <p>Your consult earnings are calculated using the rates below. Note that rates can vary based on client and consult type and no additional service fee is deducted from these rates.</p>
        </div>
      </div>
    </section>

    <section class="consult-rates-body">
      <div class="container-fluid">
        <div class="container">
          <b-table
            :data="rates"
            :loading="isLoading"
            :striped="true"
            :hoverable="true"
            :icon-pack="fa"
            :default-sort="['clientName', 'asc']"
            :columns="columns">
          </b-table>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'consult-rates',
  data: function () {
    return {
      columns: [
        {
          field: 'clientName',
          label: 'Client',
          sortable: true
        },
        {
          field: 'Compensation Unit',
          label: 'Rate Type'
        },
        {
          field: 'Display Name',
          label: 'Consult Type',
          sortable: true
        },
        {
          field: 'Paid to Provider',
          label: 'Rate'
        },
        {
          field: 'SLA',
          label: 'Required Response Time'
        }
      ],
      rates: [],
      isLoading: true
    }
  },
  async created () {
    this.isLoading = true
    await this.getRates()
    this.isLoading = false
  },
  methods: {
    async getRates () {
      try {
        const response = await this.$airtable.getConsultRates()
        const consultRates = response.data

        consultRates.forEach((record) => {
          if (record['Client Name']) {
            var clientName = record['Client Name'][0]
            record.clientName = clientName
          }

          if (record['Paid to Provider']) {
            var rate = record['Paid to Provider']
            var formattedRate = '$' + rate
            record['Paid to Provider'] = formattedRate
          }

          if (record['SLA']) {
            var SLA = record['SLA']
            var formattedSLA

            if (SLA < 3600) {
              formattedSLA = (SLA / 60).toString() + ' Minutes'
            } else {
              formattedSLA = (SLA / 3600).toString() + ' Hours'
            }

            record['SLA'] = formattedSLA
          }

          this.rates.push(record)
        })
      } catch (error) {
        console.error(Object.keys(error), error.message)
      }
    }
  }
}
</script>
