<template lang="html">
  <a v-if="dashboardUrl" :href="this.dashboardUrl" :disabled="!this.dashboardUrl.includes('connect.stripe.com')" target="_blank" id="stripe-button" class="card-footer-item"><span>View Your Dashboard</span></a>
  <a v-else :href="this.connectUrl" id="stripe-button" class="card-footer-item"><span>Connect with Stripe</span><i class="fas fa-angle-down"></i></a>
</template>

<script>
export default {
  name: 'stripe-button',
  props: [
    'accountId',
    'dashboardUrl',
    'connectUrl'
  ],
  data () {
    return {

    }
  },
  created () {
    this.setup()
  },
  methods: {
    async setup () {
      this.claims = await this.$auth.getUser()
    }
  }
}
</script>
