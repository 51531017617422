<template>
  <div class='assignment-page__container'>
    <div class='assignment-page__content'>
      <h1>This consult has been declined</h1>
      <p>You have already declined this consult and it has been routed to another provider. You can safely ignore the notification that took you here and wait for the next one.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assignments-prev-declined'
}
</script>
