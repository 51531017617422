<template>
  <div id="okta-signin-container-clinician">
    <div id="okta-sign-in" class="auth-container main-container no-beacon">
      <div class="auth-content cx-app">
        <h1>Introducing a new <br /> <a href="http://clinicians.wheel.health/login">Clinician Experience</a></h1>
        <p>The new experience will unlock access to consults, consult history, earnings, schedule, and more. This site will serve
as your hub for everything Wheel!</p>
        <p><em>This dashboard is no longer accessible. Please make sure to bookmark the new site.</em></p>
        <a class="button button-primary" href="http://clinicians.wheel.health/login">Go to the new Clinician Experience</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClinicianLogin'
}
</script>
